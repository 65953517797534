import React from "react"
import PostItem from "../postitem"

const Hit = ({ hit }) => (
  <PostItem
    slug={hit.fields.slug}
    background={hit.background}
    title={hit.title}
    date={hit.date}
    timeToRead={Math.ceil(hit.minutes)}
    description={hit.description}
    author={hit.author}
    category={hit.category}
  />
)

export default Hit